/**
 * QR Code Modal
 * 
 * Modal that shows a team's QR code for their access_code. Used so users can scan this code
 * directly from the host's phone to log in without the host having to read out the access code manually.
 */
import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'

const QRCodeModal = ({ modalShow, modalClose, team }) => {
  return (
    <Modal show={modalShow} onHide={modalClose} centered scrollable={true} aria-labelledby="progress-modal-title">
      <Modal.Header className={`card-header`} closeButton>
        <Modal.Title id="progress-modal-title" className={`card-title mb-2`}>
          QR Code {team && ` - ${team.access_code}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`card-body bg-white text-dark`}>
        <p className="text-center mb-0">Scan this QR code:</p>
        {team && <div className={`text-center`} dangerouslySetInnerHTML={{ __html: team.qr_code }} />}
        <p className="text-center">
          Or go to <strong>play.watsonadventures.com</strong> <br /> and enter{' '}
          {team && <span className="h5">{team.access_code}</span>}.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button className={`btn btn-block btn-tiny mt-4`} onClick={modalClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default QRCodeModal

QRCodeModal.propTypes = {
  modalShow: PropTypes.bool,
  modalClose: PropTypes.func,
  team: PropTypes.object,
}