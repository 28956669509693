/**
 * Host Checklist Question
 * 
 * Host representation of a normal Checklist question.
 * Allows a host to see what checklist questions were completed by
 * a particular team, and verify them if necessary.
 * Used in the Host Hunt Dashboard.
 */

import React from 'react'
import SvgCheck from '../svg/check'

class HostChecklistQuestion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hunt_status: `pending`,
    }
  }

  render() {
    const { question, checked } = this.props
    const checkedClass = checked ? `checked` : ``

    return (
      <div className="checklist-item-wrapper">
        <div className="checklist-item card mb-3">
          <div className="card-header">
            <div className="row">
              <div className="col">
                <div className={`checkbox disabled ${checkedClass}`}>
                  <span className={`svg-container d-flex align-items-center justify-content-center w-100 h-100`}>
                    <SvgCheck />
                  </span>
                </div>
              </div>
              <div className="col text-right points">{question.answer_value} pts</div>
            </div>
          </div>
          <div className="card-body" dangerouslySetInnerHTML={{ __html: question.description }} />
        </div>
      </div>
    )
  }
}

export default HostChecklistQuestion
