/**
 * Host Team Progress Modal
 * 
 * Modal that shows each team, and where they are at in terms of progress of the hunt.
 * Shows the team, their progress (in terms of questions completed), and their total score, for hosts
 * to be able to estimate how far along each team is in the hunt.
 */

import React from 'react'
import { navigate } from 'gatsby'
import Modal from 'react-bootstrap/Modal'
import SvgCheck from '../svg/check'

const TeamProgressModal = ({ modalShow, modalClose, hunt, teams }) => {
  return (
    <Modal show={modalShow} onHide={modalClose} centered scrollable={true} aria-labelledby="progress-modal-title">
      <Modal.Header className={`card-header`} closeButton>
        <Modal.Title id="progress-modal-title" className={`card-title mb-2`}>Team Progress</Modal.Title>
      </Modal.Header>
      <Modal.Body className={`card-body bg-white text-dark`}>
        <div className="row mb-3">
          <div className="col"><strong>Team</strong></div>
          <div className="col"><strong>Progress</strong></div>
          <div className="col"><strong>Score</strong></div>
        </div>
        {
          teams.map(team => {
            if(!team.permanent){
              var team_completed = 0
              if(team.answers){
                for( var key in team.answers) {
                  var val = team.answers[key]
                  if(val.isCorrect){
                    team_completed += 1
                  } else {
                    if(val.numGuesses >= 2){
                      team_completed += 1
                    }
                  }
                }
              }
              if(team.secondary_answers){
                for( var key in team.secondary_answers) {
                  var val = team.secondary_answers[key]
                  if(val.isCorrect){
                    team_completed += 1
                  } else {
                    if(val.numGuesses >= 2){
                      team_completed += 1
                    }
                  }
                }
              }
              const team_finished = team.end_time ? `text-success` : ``
              return(
                <div key={team.id} className="pb-2 mb-2" style={{borderBottom: `1px solid #ccc`}}>
                  <div className="row">
                    <div className={`col team-number`}>
                      <span className={`title h6`}>{team.number } - {team.access_code}</span>
                      <span className={`d-block team-details`}>
                        {team.team_name ? team.team_name : ``}
                      </span>
                    </div>
                    <div className={`col ${team_finished}`}>{team_completed} / {hunt.questions.length + hunt.secondary_questions.length}
                    </div>
                    <div className={`col`}>{team.total_score}</div>
                  </div>
                </div>
              )
            }
          })
        }

        
        
      </Modal.Body>
      <Modal.Footer>
        <button className={`btn btn-block btn-tiny mt-4`} onClick={modalClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

TeamProgressModal.defaultProps = {
  modalShow: false,
  modalDismissed: false,
  modalClose: () => {},
}

export default TeamProgressModal
