import React from 'react'

const SvgQRCode = props => (
  <svg width={16} height={16} fill="none" viewBox="0 0 24 24" style={{ marginTop: `-3px` }} {...props}>
    <path fill="currentColor" fillRule="evenodd" d="M9 3H3v6h2V5h4V3ZM3 21v-6h2v4h4v2H3ZM15 3v2h4v4h2V3h-6Zm4 12h2v6h-6v-2h4v-4ZM7 7h4v4H7V7Zm0 6h4v4H7v-4Zm10-6h-4v4h4V7Zm-4 6h4v4h-4v-4Z" clipRule="evenodd"/>
  </svg>

)

export default SvgQRCode
