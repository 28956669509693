import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'


const PersistantCheckbox = ({ id, content=`QR Code Shown` }) => {

  const [selected, setSelected] = React.useState(localStorage.getItem(`codeChecked-${id}`) === `true`)

  const ToggleSwitch = ({ checked, id, onChange }) => (
    <span className="d-block team-details">
      <div className="form-check">
        <input
          id={`codeChecked-${id}`}
          className="form-check-input"
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
        <label className="form-check-label" htmlFor={`codeChecked-${id}`}>
          {content}
        </label>
      </div>
    </span>
  )

  return (
    <ToggleSwitch
      checked={selected}
      id={id}
      label={content}
      onChange={e => {
        localStorage.setItem(`codeChecked-${id}`, `${e.target.checked}`)
        setSelected(e.target.checked)
        console.log(e.target.checked)
      }}
    />
  )
}

PersistantCheckbox.propTypes = {
  content: PropTypes.string,
  id: PropTypes.number,
}

export default PersistantCheckbox
