/**
 * Bonus Points Component
 * 
 * An individual representation of a bonus point item.
 * Allows host to edit or delete this item
 * 
 * Used in ./bonus-points-list.js
 */

import React from 'react'
import API from '../../utils/api'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'

import FloatingInput from '../floating-input'

class BonusPoints extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      confirmDelete: false,
      value: 0,
      description: ``,
    }
    this.modalClose = this.modalClose.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.update = this.update.bind(this)
    this.delete = this.delete.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
  }

  componentDidMount() {
    const { value, description } = this.props
    this.setState({ value: value, description: description })
  }

  modalClose() {
    this.setState({ modalShow: false })
  }

  toggleModal() {
    this.setState({ modalShow: !this.state.modalShow })
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  update() {
    const { id } = this.props
    let new_data = {
      value: this.state.value,
      description: this.state.description,
    }
    API.put(`/host/scoring/score/${id}/`, new_data, {
      headers: {
        Authorization: `JWT ${localStorage.getItem(`token`)}`,
      },
    }).then(res => {
      console.log(res)
      this.modalClose()
    })
  }

  delete() {
    if (this.state.confirmDelete == true) {
      const { id } = this.props
      API.delete(`/host/scoring/score/${id}/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem(`token`)}`,
        },
      }).then(res => {
        console.log(res)
      })
    } else {
      this.setState({ confirmDelete: true })
    }
  }

  render() {
    const { value, description } = this.props
    return (
      <>
        <div className="bonus-points px-2 py-2 mb-2">
          <div className="row">
            <div className="col-3">
              <p className={`points title h6 mb-0`}>{value} pts</p>
            </div>
            <div className={`col description`}>{description}</div>
            <div className={`col text-right edit`}>
              <button className="btn text-primary px-0 py-0" onClick={this.toggleModal} title="Edit">
                <FontAwesomeIcon icon={faPencilAlt} />
              </button>
            </div>
          </div>
        </div>
        <Modal className={`light-modal`} show={this.state.modalShow} onHide={this.modalClose} centered aria-labelledby="edit-modal-title">
          <Modal.Header closeButton>
            <Modal.Title id="edit-modal-title">Edit Bonus Points</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={`mt-5`}>
              <FloatingInput
                name={`value`}
                placeholder={`Point Value`}
                onChange={this.handleUpdate}
                value={this.state.value}
              />
              <FloatingInput
                name={`description`}
                placeholder={`Description`}
                onChange={this.handleUpdate}
                value={this.state.description}
              />
              <button className={`btn btn-block btn-success mb-3`} onClick={this.update}>
                Save
              </button>
              <button className={`btn btn-block btn-danger mb-3`} onClick={this.delete}>
                Delete
              </button>
              {this.state.confirmDelete === true && (
                <p className={`text-center text-danger`}>Are you sure you want to delete this?</p>
              )}
              <button className={`btn btn-block btn-cancel btn-tiny text-primary`} onClick={this.modalClose}>
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export default BonusPoints
