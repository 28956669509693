/**
 * Team Scoring Component
 * 
 * Displays a team's current score and breakdown inside the host dashboard.
 * Allows hosts to have an overview of how well each team is doing.
 */

import React from 'react'
import PropTypes from 'prop-types'
import Alert from 'react-bootstrap/Alert'
import API from '../../utils/api'
import BonusPoints from './bonus-points'

export default class TeamScore extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: ``,
      description: ``,
      data: [],
      initialized: false,
      alertShow: false,
    }
    this.handleRefresh = this.handleRefresh.bind(this)
    this.initialize = this.initialize.bind(this)
    this.timeDeduction = this.timeDeduction.bind(this)
  }

  componentDidMount() {
    this.setState({ data: this.props.host_scores })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.host_scores != prevProps.host_scores) {
      this.setState({
        data: this.props.host_scores,
      })
    }
  }

  initialize() {
    if (!this.state.initialized && this.state.data != this.props.host_scores) {
      this.setState({
        initialized: true,
        data: this.props.host_scores,
      })
    }
  }

  handleRefresh() {
    API.get(`/host/scoring/${this.props.team_id}/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem(`token`)}`,
      },
    })
      .then(res => {
        let json = res.data
        this.setState({
          data: json,
          //alertShow: true,
        })
        console.log(json)
        this.props.handle_refresh()
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  bonusPoints() {
    let score = 0
    this.state.data.map(item => (score += item.value))
    return score
  }

  basePoints() {
    const { team } = this.props
    let score = team.score ? team.score : 0
    if (team.secondary_answers !== null) {
      for (var id in team.secondary_answers) {
        if(team.secondary_answers[id].points){
          score += team.secondary_answers[id].points
        }
      }
    }
    return score
  }

  checklistPoints() {
    let score = 0
    const { team } = this.props
    if (team.checklist_answers !== null) {
      for (var id in team.checklist_answers) {
        if(team.checklist_answers[id].points){
          score += team.checklist_answers[id].points
        }
      }
    }
    return score
  }

  mysteryMessagePoints() {
    let score = 0
    const { team } = this.props
    if (team.mystery_message_answers !== null && team.mystery_message_answers.points !== undefined) {
      score += team.mystery_message_answers.points
    }
    return score
  }

  emergencyMessagePoints() {
    let score = 0
    const { team } = this.props
    if (team.emergency_message_answers !== undefined && team.emergency_message_answers !== null && team.emergency_message_answers.points !== undefined) {
      score += team.emergency_message_answers.points
    }
    return score
  }

  timeDeduction() {
    const { team, hunt_duration, enable_timer } = this.props
    if(!enable_timer) return 0
    let duration = (hunt_duration !== undefined ? hunt_duration : 2) * 60 // in seconds
    let seconds = 0
    // deduct points for going over time
    let huntStart = team !== undefined ? team.start_time : false
    let huntEnd = team !== undefined ? team.end_time : false
    if (huntStart) {
      // hunt has started, figure out elapsed seconds from start_time to now
      let now = new Date().getTime()
      if (huntEnd) {
        now = Date.parse(huntEnd)
      }
      seconds = Math.floor((now - Date.parse(huntStart)) / 1000)
    }

    let seconds_left = duration - seconds
    if (seconds_left < 0) {
      // deduct points after a certain amount
      // default is 3 points after every 5 minutes
      var five_min_intervals = Math.floor(-seconds_left / 60 / 5)
      //console.log('Five Minute Intervals: ' + five_min_intervals)
      var pointDeduction = 3 * five_min_intervals
      return pointDeduction
    }
    return 0
  }

  render() {
    const basePoints = this.basePoints()
    const bonusPoints = this.bonusPoints()
    const checklistPoints = this.checklistPoints()
    const mysteryMessagePoints = this.mysteryMessagePoints()
    const emergencyMessagePoints = this.emergencyMessagePoints()
    const timeDeduction = this.timeDeduction()
    const score = basePoints + checklistPoints + mysteryMessagePoints + emergencyMessagePoints + bonusPoints - timeDeduction
    return (
      <>
        <div className={`card-footer`}>
          <div className={`row`}>
            <div className={`col-8`}>
              <span className={`d-block total-points`}>Total Points</span>
              <small>
                ({basePoints} base + {checklistPoints !== 0 && <>{checklistPoints} checklist +</>}
                {` `}
                {mysteryMessagePoints !== 0 && <>{mysteryMessagePoints} mystery message +</>} {emergencyMessagePoints !== 0 && <>{emergencyMessagePoints} emergency message +</>} {bonusPoints} bonus{` `}
                {timeDeduction !== 0 && <>- {timeDeduction} time deduction </>})
              </small>
            </div>
            <div className={`col-4 text-lowercase text-right`}>
              <p className={`title h6 mb-0`}>{score} pts</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}

TeamScore.propTypes = {
  team_id: PropTypes.number,
  hunt_duration: PropTypes.number,
  enable_timer: PropTypes.bool,
}

TeamScore.defaultProps = {
  team_id: ``,
  hunt_duration: 2,
  enable_timer: false,
}
