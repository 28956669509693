import React from 'react'

const SvgCheck = props => (
  <svg width={16} height={15} viewBox="0 0 16 15" {...props}>
    <path
      d="m11.351 20.736-4.006-4.608 1.335-1.535 2.671 3.072 9.348-10.753 1.335 1.537z"
      fill="#fff"
      fillRule="evenodd"
      transform="translate(-6.61 -6.213)"
    />
  </svg>
)
export default SvgCheck
