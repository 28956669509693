/**
 * Bonus Points List Component
 * 
 * Display a list of bonus points and their reasons of a particular team.
 * Used in the Host Hunt Dashboard.
 */

import React from 'react'
import PropTypes from 'prop-types'
import BonusPoints from './bonus-points'

export default class BonusPointsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      initialized: false,
      alertShow: false,
    }
    this.initialize = this.initialize.bind(this)
  }

  componentDidMount() {
    this.setState({ data: this.props.host_scores })
  }

  componentDidUpdate(prevProps) {
    if (this.props.host_scores != prevProps.host_scores) {
      this.setState({
        data: this.props.host_scores,
      })
    }
  }

  initialize() {
    if (!this.state.initialized && this.state.data != this.props.host_scores) {
      this.setState({
        initialized: true,
        data: this.props.host_scores,
      })
    }
  }

  render() {
    const { data } = this.state
    return (
      <>
        <div>
          {data.length > 0 && (
            <>
              <span className={`d-block team-details`}>
                <strong>Bonus Points</strong>
              </span>

              {data.map(item => (
                <BonusPoints key={item.id} id={item.id} value={item.value} description={item.description} />
              ))}
            </>
          )}
        </div>
      </>
    )
  }
}

BonusPointsList.propTypes = {
  team_id: PropTypes.number,
  host_scores: PropTypes.array,
}

BonusPointsList.defaultProps = {
  team_id: ``,
  host_scores: [],
}
