/**
 * Host Hunt Page
 * 
 * Host view into a singular hunt. Shows them all of the details they need
 * to run a hunt, including hunt time, teams, host scripts, etc.
 */
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import dayjs from 'dayjs'
import { Link, navigate } from 'gatsby'
import { Tabs, Tab, Accordion, Card } from 'react-bootstrap'
import HostContext from '../../utils/host-context'
import HostLoginWrapper from '../../components/host/login-wrapper'
import API from '../../utils/api'

import HostChecklistQuestion from '../../components/host/checklist-question'
import BonusPointsList from '../../components/host/bonus-points-list'
import TeamScore from '../../components/host/scoring'

import HostPhotoChecklistQuestion from '../../components/host/photo-checklist-question'
import SvgCirclePlus from '../../components/svg/plus-circle'
import SvgQRCode from '../../components/svg/qr-code'
import TeamProgressModal from '../../components/host/team-progress-modal'
import QRCodeModal from '../../components/host/qr-code-modal'
import PersistantCheckbox from '../../components/host/PersistantCheckbox'
import NotificationSendModal from '../../components/host/notificationSendModal'
import NotificationListModal from '../../components/host/notificationListModal'


export default class HostHuntPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active_hunt: null,
      sorted_teams: [],
      active_image: {},
      modalShow: false,
      teamModalShow: false,
      is_updating: false,
      qrModalShow: false,
      active_team: null,
      notificationSendModalShow: false,
      notificationListModalShow: false,
    }
    this.setActivePhoto = this.setActivePhoto.bind(this)
    this.modalClose = this.modalClose.bind(this)
    this.update = this.update.bind(this)
    this.teamModalClose = this.teamModalClose.bind(this)
    this.qrModalClose = this.qrModalClose.bind(this)
    this.showTeamQR = this.showTeamQR.bind(this)
    this.notificationSendModalClose = this.notificationSendModalClose.bind(this)
    this.notificationListModalClose = this.notificationListModalClose.bind(this)
  }

  componentDidMount() {
    this.update()
    this.interval = setInterval(this.update, 3000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  update() {
    const hunt_id = this.props.location.hash.substring(1)
    const { is_updating } = this.state
    if(!is_updating){
      this.setState({ is_updating: true })
      API.get(`/host/hunt/${hunt_id}/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem(`token`)}`,
        },
      }).then((res) => {
        // console.log(res)
        this.setState({ is_updating: false, active_hunt: res.data })
        this.setActiveHunt(res.data)
      })
    }
  }

  sortTeams() {
    const { active_hunt } = this.state
    if (active_hunt) {
      if (this.active_hunt.teams !== undefined) {
        let sorted_teams = this.getSortedTeams(this.active_hunt.teams)
        this.setState({ sorted_teams: sorted_teams })
      }
    }
  }

  getSortedTeams(team_list) {
    if(!team_list || team_list == undefined) return []
    // team_list.map(team => {
    //   team.total_score = this.getTeamScore(team, hunt_duration, timer_enabled)
    // })

    return team_list.sort((a, b) => {
      // check if it's permanent, move it down
      if (a.permanent) return 1
      if (b.permanent) return -1
      // sort by score
      // if the first item has a higher number, move it down
      // if the first item has a lower number, move it up
      if (a.total_score > b.total_score) return -1
      if (a.total_score < b.total_score) return 1

      return 0
    })
  }

  getTeamScore(team, hunt_duration, timer_enabled) {
    var total_score = 0

    // normal score
    total_score += team.score

    // checklist points
    if (team.checklist_answers !== null) {
      for (var id in team.checklist_answers) {
        total_score += team.checklist_answers[id].points
      }
    }

    // mystery message points
    if (team.mystery_message_answers !== null) {
      total_score += team.mystery_message_answers.points
    }

    // bonus points
    if (team.host_scores !== null) {
      team.host_scores.map(item => {
        total_score += item.value
      })
    }

    // time deduction
    if(timer_enabled){
      let duration = hunt_duration * 60 // in seconds
      let seconds = 0
      // deduct points for going over time
      let huntStart = team.start_time
      let huntEnd = team.end_time
      if (huntStart) {
        // hunt has started, figure out elapsed seconds from start_time to now
        let now = new Date().getTime()
        if (huntEnd) {
          now = Date.parse(huntEnd)
        }
        seconds = Math.floor((now - Date.parse(huntStart)) / 1000)
      }

      let seconds_left = duration - seconds
      //console.log(`Duration: ${duration}  Seconds: ${seconds}  Seconds Left: ${seconds_left}`)
      if (seconds_left < 0) {
        // deduct points after a certain amount
        // default is 3 points after every 5 minutes
        var five_min_intervals = Math.floor(-seconds_left / 60 / 5)
        //console.log('Five Minute Intervals: ' + five_min_intervals)
        var pointDeduction = 3 * five_min_intervals
        //console.log('pointDeduction: ' + pointDeduction)
        //return pointDeduction
        total_score -= pointDeduction
      }
    }

    //console.log(`Team no: ${team.access_code}: ${total_score}`)

    return total_score
  }

  handleSelectedAccordion(event) {
    const target = event.target
    var els = target.parentElement.parentElement.querySelectorAll(`.host-checklist-card .card-header`)
    for (let i = 0; i < els.length; i++) {
      if (!els[i].isEqualNode(target)) {
        els[i].classList.remove(`open`)
      }
    }
    target.classList.toggle(`open`)
  }

  setActivePhoto(photo) {
    this.setState({
      active_image: photo,
      modalShow: true,
    })
  }

  showTeamQR(team) {
    this.setState({
      active_team: team,
      qrModalShow: true,
    })
  }

  modalClose() {
    this.setState({
      modalShow: false,
    })
  }

  teamModalClose() {
    this.setState({
      teamModalShow: false,
    })
  }

  qrModalClose() {
    this.setState({
      qrModalShow: false,
    })
  }

  notificationSendModalClose() {
    this.setState({
      notificationSendModalShow: false,
    })
  }

  notificationListModalClose() {
    this.setState({
      notificationListModalShow: false,
    })
  }

  render() {
    const {
      active_hunt,
      active_team,
      teamModalShow,
      qrModalShow,
      notificationSendModalShow,
      notificationListModalShow,
    } = this.state
    let sorted_teams = []
    if(active_hunt){
      sorted_teams = this.getSortedTeams(active_hunt.teams)
    }
    return (
      <HostLoginWrapper>
        <HostContext.Consumer>
          {({ addPointsTeam, setActiveHunt }) => {
            this.setActiveHunt = setActiveHunt
            return (
              <>
                {!active_hunt && <h2 className="py-3 px-5">Loading...</h2>}
                {active_hunt && (
                  <>
                    <div className="site-sub-header px-4 py-4 mb-4">
                      <h1 className={`title h5 text-uppercase mb-0`}>
                        {active_hunt.display_title ? active_hunt.display_title : active_hunt.title}
                      </h1>
                      <div className="row">
                        <div className="col">
                          <p className="mb-0">
                            {active_hunt.event_date
                              ? dayjs(active_hunt.event_date).format(`MM/DD/YYYY h:mm A`)
                              : dayjs(active_hunt.start).format(`MM/DD/YYYY h:mm A`)}{' '}
                            <span> - </span>
                            {active_hunt.end && dayjs(active_hunt.end).format(`MM/DD/YYYY h:mm A`)}
                          </p>
                          <p className="mb-0">{active_hunt.location}</p>
                          <p className="mb-0">{active_hunt.client}</p>
                          <p>
                            Total Players Reported: <strong>{active_hunt.player_count}</strong>
                          </p>
                        </div>
                      </div>
                      {active_hunt.hunt_scripts.length > 0 && (
                        <Link to={`/host/scripts/#${active_hunt.id}`} className="btn btn-block btn-primary mt-3">
                          View Host Scripts
                        </Link>
                      )}
                    </div>
                    <div className="content px-4 pb-5">
                      <div className="row">
                        <div className="col-md pb-3">
                          <button
                            className="btn btn-block btn-danger"
                            onClick={() => this.setState({ teamModalShow: true })}>
                            See Team Progress
                          </button>
                          <button
                            className="btn btn-block btn-danger"
                            onClick={() => this.setState({ notificationSendModalShow: true })}>
                            Send Message to Teams
                          </button>
                          <button
                            className="btn btn-block btn-danger"
                            onClick={() => this.setState({ notificationListModalShow: true })}>
                            View previous messages
                          </button>

                        </div>
                        <div className="col-md pb-3">
                          <a
                            className="btn btn-block btn-success"
                            href={`/host/gallery/#${active_hunt.id}`}
                            target="_blank"
                            rel="noreferrer">
                            Photo Gallery
                          </a>
                        </div>
                      </div>
                      <div className="row">
                        {active_hunt.teams !== undefined &&
                          sorted_teams.map(team => {
                            if (team.permanent) {
                              return (
                                <div key={team.id} className="col-md-6 mb-3">
                                  <div className="card team-card">
                                    <div className="card-header bg-danger">
                                      <div className="row">
                                        <div className={`col team-number`}>
                                          Team No.{' '}
                                          {team.number > 0 && <span className={`title h6 mr-4`}>{team.number}</span>}{' '}
                                          <span className={`title h6`}>{team.access_code}</span>
                                        </div>
                                        <div className={`col text-right`}>Permanent</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            }

                            let team_captain = `(None)`
                            let team_status = `Not Started`
                            if (team.leader && team.members !== undefined && team.members[team.leader] !== undefined) {
                              if (team.members[team.leader].name) {
                                team_captain = team.members[team.leader].name
                              } else {
                                team_captain = `(No Name)`
                              }
                            }
                            if (team.end_time) {
                              team_status = `Finished`
                            } else if (team.start_time) {
                              team_status = `In Progress`
                            }
                            return (
                              <div key={team.id} className="col-md-6 mb-3">
                                <div className={`card team-card`}>
                                  <div className={`card-header`}>
                                    <div className={`row`}>
                                      <div className={`col team-number`}>
                                        Team No. <span className={`title h6 mr-4`}>{team.number}</span>{' '}
                                        <span className={`title h6`}>{team.access_code}</span>
                                      </div>
                                      <div className={`col text-right`}>
                                        <button
                                          className={`btn py-0 px-4`}
                                          onClick={() => {
                                            this.showTeamQR(team)
                                          }}
                                          title="View Team QR Code">
                                          <SvgQRCode />
                                        </button>
                                        <button
                                          className={`btn px-0 py-0`}
                                          onClick={() => addPointsTeam(team.id)}
                                          title="Add Bonus Points">
                                          <SvgCirclePlus />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`card-body px-0 py-0`}>
                                    <Tabs
                                      defaultActiveKey={`details-${team.id}`}
                                      id={`tabs-${team.id}`}
                                      className={`host-tabs`}>
                                      <Tab eventKey={`details-${team.id}`} title={`Details`}>
                                        <div className="px-4 py-3">
                                          <span className={`d-block team-details`}>
                                            <strong>Name</strong> {team.team_name ? team.team_name : `(None)`}
                                          </span>
                                          <span className={`d-block team-details`}>
                                            <strong>Captain</strong> {team_captain}
                                          </span>
                                          <span className={`d-block team-details`}>
                                            <strong>Status</strong> {team_status}
                                          </span>
                                          <span className={`d-block team-details`}>
                                            <strong>Starting Question</strong> {team.start} ({team.direction})
                                          </span>
                                          <span className={`d-block team-details`}>
                                            <strong>Player Count</strong> {team.player_count}
                                          </span>
                                          <span className={`d-block team-details`}>
                                            <strong>Notes</strong> {team.notes}
                                          </span>

                                          <hr />

                                          <PersistantCheckbox id={team.id} content="QR Code Shown" />

                                          <BonusPointsList
                                            team_id={team.id}
                                            host_scores={team.host_scores}
                                            handle_refresh={this.handleRefresh}
                                          />
                                        </div>
                                      </Tab>
                                      {active_hunt.checklists.length > 0 && (
                                        <Tab
                                          eventKey={`checklists-${team.id}`}
                                          title={active_hunt.checklists.length > 1 ? `Checklists` : `Checklist`}>
                                          <div className="px-4 py-3">
                                            <Accordion>
                                              {active_hunt.checklists.map(checklist => (
                                                <Card
                                                  key={`checklist-${checklist.id}-${team.id}`}
                                                  className="host-checklist-card">
                                                  <Accordion.Toggle
                                                    as={Card.Header}
                                                    eventKey={`accordion-${checklist.id}-${team.id}`}
                                                    onClick={this.handleSelectedAccordion}>
                                                    {checklist.display_title}
                                                  </Accordion.Toggle>
                                                  <Accordion.Collapse eventKey={`accordion-${checklist.id}-${team.id}`}>
                                                    <Card.Body className="px-0 py-0">
                                                      {checklist.questions.map(question => (
                                                        <div key={`checklist-question-${question.id}-${team.id}`}>
                                                          {checklist.type == `photo` ? (
                                                            <HostPhotoChecklistQuestion
                                                              question={question}
                                                              checked={
                                                                team.checklist_answers !== null &&
                                                                team.checklist_answers[checklist.id] !== undefined &&
                                                                team.checklist_answers[checklist.id].checked[
                                                                  question.id
                                                                ] !== undefined &&
                                                                team.checklist_answers[checklist.id].checked[
                                                                  question.id
                                                                ].checked
                                                              }
                                                              photo={
                                                                team.checklist_answers !== null &&
                                                                team.checklist_answers[checklist.id] !== undefined &&
                                                                team.checklist_answers[checklist.id].checked[
                                                                  question.id
                                                                ] !== undefined &&
                                                                team.checklist_answers[checklist.id].checked[
                                                                  question.id
                                                                ].photo
                                                              }
                                                              onSelect={this.setActivePhoto}
                                                            />
                                                          ) : (
                                                            <HostChecklistQuestion
                                                              question={question}
                                                              checked={
                                                                team.checklist_answers !== null &&
                                                                team.checklist_answers[checklist.id] !== undefined &&
                                                                team.checklist_answers[checklist.id].checked[
                                                                  question.id
                                                                ] !== undefined &&
                                                                team.checklist_answers[checklist.id].checked[
                                                                  question.id
                                                                ].checked
                                                              }
                                                            />
                                                          )}
                                                        </div>
                                                      ))}
                                                    </Card.Body>
                                                  </Accordion.Collapse>
                                                </Card>
                                              ))}
                                            </Accordion>
                                          </div>
                                        </Tab>
                                      )}
                                    </Tabs>
                                  </div>
                                  <TeamScore
                                    team_id={team.id}
                                    team={team}
                                    host_scores={team.host_scores}
                                    hunt_duration={active_hunt.duration}
                                    enable_timer={active_hunt.enable_timer}
                                    handle_refresh={this.handleRefresh}
                                  />
                                </div>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  </>
                )}
                <Modal
                  show={this.state.modalShow}
                  onHide={this.modalClose}
                  centered
                  className=""
                  aria-labelledby="photo-details-modal-title">
                  <h3 id="photo-details-modal-title" className="sr-only">
                    Photo Details
                  </h3>
                  <Modal.Body className={`text-center text-uppercase px-0 py-0`}>
                    {this.state.active_image.image !== undefined && (
                      <>
                        <img className="img-fluid" src={this.state.active_image.image} />
                      </>
                    )}
                    <button className={`btn btn-block btn-tiny text-uppercase mt-5`} onClick={this.modalClose}>
                      Close
                    </button>
                  </Modal.Body>
                </Modal>
                <TeamProgressModal
                  hunt={active_hunt}
                  teams={sorted_teams}
                  modalShow={teamModalShow}
                  modalClose={this.teamModalClose}
                />
                <QRCodeModal team={active_team} modalShow={qrModalShow} modalClose={this.qrModalClose} />
                <NotificationSendModal
                  hunt={active_hunt}
                  modalShow={notificationSendModalShow}
                  modalClose={this.notificationSendModalClose}
                />
                <NotificationListModal
                  hunt={active_hunt}
                  modalShow={notificationListModalShow}
                  modalClose={this.notificationListModalClose}
                />
              </>
            )
          }}
        </HostContext.Consumer>
      </HostLoginWrapper>
    )
  }
}
