/**
 * Send Notifcation Modal
 *
 * Modal that allows host to send a notification to a team.
 */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import API from '../../utils/api'
import Modal from 'react-bootstrap/Modal'

const NotificationSendModal = ({ modalShow, modalClose, hunt }) => {
  // console.log(hunt)

  const [messageTitle, setmessageTitle] = useState(``)
  const [messageBody, setmessageBody] = useState(``)
  const [messageType, setmessageType] = useState(1)
  const [messageSent, setmessageSent] = useState(false)
  const [messageLoading, setmessageLoading] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    setmessageLoading(true)
    const notificationData = {
      action: messageType,
      title: messageTitle,
      message: messageBody,
      hunt: hunt.id,
    }

    const post_data = new FormData()
    post_data.append(`title`, messageTitle)
    post_data.append(`message`,  messageBody)
    post_data.append(`action`, messageType)
    post_data.append(`hunt`, hunt.id)


    API.post(`/hunt/${hunt.id}/notifications/`, post_data, {
      headers: {
        Authorization: `JWT ${localStorage.getItem(`token`)}`,
      },
    }).then(res => {
      console.log(res)
      setmessageTitle(``)
      setmessageBody(``)
      setmessageSent(true)
      setmessageLoading(false)
    })
  }

  return (
    <Modal show={modalShow} onHide={modalClose} centered scrollable={true} aria-labelledby="send-notification">
      <Modal.Header className={`card-header`} closeButton>
        <Modal.Title id="progress-modal-title" className={`card-title mb-2`}>
          Send Notification
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`card-body bg-white text-dark`}>
        {messageSent && (
          <div className="alert alert-success" role="alert">
            Notification Sent!
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="messageTitle">Title</label>
            <input
              type="text"
              className="form-control"
              id="messageTitle"
              placeholder="Enter title"
              value={messageTitle}
              onChange={e => setmessageTitle(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="messageBody">Message</label>
            <textarea
              className="form-control"
              id="messageBody"
              rows="3"
              value={messageBody}
              onChange={e => setmessageBody(e.target.value)}></textarea>
          </div>

          <button className="btn btn-block btn-danger" disabled={messageLoading}>
            {messageLoading ? `Sending...` : `Send`}
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button className={`btn btn-block btn-tiny mt-4`} onClick={modalClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default NotificationSendModal


NotificationSendModal.propTypes = {
  modalShow: PropTypes.bool,
  modalClose: PropTypes.func,
  hunt: PropTypes.object,
}