/**
 * Send Notifcation Modal
 *
 * Modal that allows host to send a notification to a team.
 */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import API from '../../utils/api'
import Modal from 'react-bootstrap/Modal'
import Notification from '../notification'

const NotificationListModal = ({ modalShow, modalClose, hunt }) => {
  //console.log(hunt)

  const [notificationData, setNotificationData] = useState([])

  useEffect(() => {
    if (hunt) {
      API.get(`/hunt/${hunt.id}/notifications/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem(`token`)}`,
        },
      }).then(res => {
        //console.log(res)
        setNotificationData(res.data.results.notifications)
      })
    }
  }, [hunt])

  return (
    <Modal show={modalShow} onHide={modalClose} centered scrollable={true} aria-labelledby="send-notification">
      <Modal.Header className={`card-header`} closeButton>
        <Modal.Title id="progress-modal-title" className={`card-title mb-2`}>
          Previous Notifications
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`card-body bg-white text-dark`}>
        {notificationData.map((notification, index) => (
          <div key={index}>
            <Notification notification={notification} />
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <button className={`btn btn-block btn-tiny mt-4`} onClick={modalClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default NotificationListModal

NotificationListModal.propTypes = {
  modalShow: PropTypes.bool,
  modalClose: PropTypes.func,
  hunt: PropTypes.object,
}
