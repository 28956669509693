/**
 * Host Photo Checklist Question
 * 
 * Host representation of a Photo Checklist question.
 * Allows a host to see what photo checklist questions were completed by
 * a particular team, and verify them if necessary.
 * Used in the Host Hunt Dashboard.
 */

import React from 'react'

class HostPhotoChecklistQuestion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: false,
      initialized: false,
      photo: null,
    }
    this.initialize = this.initialize.bind(this)
    this.selectImage = this.selectImage.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  componentDidUpdate() {
    this.initialize()
  }

  initialize() {
    if (!this.state.initialized && this.props.checked !== this.state.checked) {
      this.setState({
        checked: this.props.checked,
        photo: this.props.photo,
        initialized: true,
      })
    }
  }

  selectImage() {
    this.props.onSelect(this.state.photo)
  }

  render() {
    const { question } = this.props
    return (
      <div className="checklist-item-wrapper">
        <div className="photo-checklist-question mb-3 row no-gutters">
          {this.state.photo && (
            <div className="col-4 text-center">
              <div
                className="img-preview w-100 h-100"
                style={{ backgroundImage: `url(${this.state.photo.image})` }}
                onClick={this.selectImage}></div>
            </div>
          )}

          <div className={this.state.photo ? `col-8` : `col-12`}>
            <div className="photo-heading px-3 py-3 text-right header-data">{question.answer_value} pts</div>
            <div className="py-3 px-3" dangerouslySetInnerHTML={{ __html: question.description }} />
          </div>
        </div>
      </div>
    )
  }
}

export default HostPhotoChecklistQuestion
